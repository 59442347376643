import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'sc-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public _data: { title: string; message: string; btnOkText: string; showComment: boolean },
  ) {}
  comment = '';

  dismiss() {
    this.dialogRef.close();
  }

  confirm() {
    this.dialogRef.close({ data: true, comment: this.comment });
  }
}
