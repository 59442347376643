{
  "name": "siglar-reporting-admin",
  "version": "3.9.5",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "build:prod": "ng build --configuration production",
    "test": "ng test",
    "test:coverage": "ng test --code-coverage",
    "lint": "ng lint",
    "lint:fix": "ng lint --fix",
    "lint:styles": "stylelint \"**/*.scss\" --fix",
    "e2e": "ng e2e",
    "ci:clean": "rimraf ./www",
    "test:ci": "ng test --no-watch --no-progress --browsers=ChromeHeadlessCI --reporters=coverage-istanbul --code-coverage"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.1",
    "@angular/cdk": "18.2.1",
    "@angular/common": "^18.2.1",
    "@angular/core": "^18.2.1",
    "@angular/forms": "^18.2.1",
    "@angular/material": "^18.2.1",
    "@angular/platform-browser": "^18.2.1",
    "@angular/platform-browser-dynamic": "^18.2.1",
    "@angular/router": "^18.2.1",
    "@auth0/auth0-angular": "^2.2.3",
    "@ngrx/effects": "^18.0.2",
    "@ngrx/schematics": "^18.0.2",
    "@ngrx/store": "^18.0.2",
    "@ngrx/store-devtools": "^18.0.2",
    "@types/mapbox-gl": "2.6.0",
    "bootstrap": "^5.3.3",
    "chart.js": "^4.4.7",
    "chartjs-adapter-date-fns": "^3.0.0",
    "chartjs-plugin-datalabels": "^2.2.0",
    "date-fns": "^4.1.0",
    "mapbox-gl": "2.6.1",
    "moment": "^2.29.1",
    "ngrx-store-localstorage": "^18.0.0",
    "ngx-toastr": "^19.0.0",
    "rxjs": "7.4.0",
    "tslib": "2.3.1",
    "xlsx": "^0.18.3",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.1",
    "@angular-eslint/builder": "13.0.1",
    "@angular-eslint/eslint-plugin": "13.0.1",
    "@angular-eslint/eslint-plugin-template": "13.0.1",
    "@angular-eslint/schematics": "13.0.1",
    "@angular-eslint/template-parser": "13.0.1",
    "@angular/cli": "^18.2.1",
    "@angular/compiler": "^18.2.1",
    "@angular/compiler-cli": "^18.2.1",
    "@angular/language-service": "^18.2.1",
    "@ngrx/eslint-plugin": "^18.0.2",
    "@types/chart.js": "^2.9.41",
    "@types/jasmine": "^5.1.4",
    "@types/jasminewd2": "^2.0.13",
    "@types/node": "^22.5.2",
    "@typescript-eslint/eslint-plugin": "^8.3.0",
    "@typescript-eslint/parser": "^8.3.0",
    "angular-eslint": "^18.3.0",
    "eslint": "^8.2.0",
    "jasmine": "^5.2.0",
    "jasmine-core": "^5.2.0",
    "jasmine-spec-reporter": "^7.0.0",
    "karma": "^6.4.4",
    "karma-chrome-launcher": "^3.2.0",
    "karma-coverage": "^2.2.1",
    "karma-coverage-istanbul-reporter": "^3.0.3",
    "karma-jasmine": "^5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "ngx-spec": "^2.1.6",
    "node-sass": "^9.0.0",
    "prettier": "3.3.3",
    "protractor": "~7.0.0",
    "stylelint": "^16.9.0",
    "stylelint-config-standard-scss": "^13.1.0",
    "ts-node": "10.4.0",
    "typescript": "5.4.5",
    "typescript-eslint": "^8.4.0"
  }
}